import Link from "../../../components/Link";
import { IDialogField, IListviewField } from "../../../services/types";
import { formatBoolean, getCurrentUrlAsSource } from "../../../services/utils";
import { RoleFull } from "./types";
import { dispForm } from "./urls";

export const name: IListviewField<RoleFull> & IDialogField<RoleFull> = {
  title: "Name",
  fieldName: "name",
  isSortable: true,
  renderListValue: (item: RoleFull) => (
    <Link href={`${dispForm}/${item.id}?${getCurrentUrlAsSource()}`}>{item.name}</Link>
  ),
  renderDialogValue: (item: RoleFull) => item.name,
};

export const forChapters: IListviewField<RoleFull> = {
  title: "Für Ortsgruppen",
  fieldName: "forChapters",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: RoleFull) => formatBoolean(item.forChapters),
};

export const forAreas: IListviewField<RoleFull> = {
  title: "Für Gebiete",
  fieldName: "forAreas",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: RoleFull) => formatBoolean(item.forAreas),
};

export const forDistricts: IListviewField<RoleFull> = {
  title: "Für Bezirke",
  fieldName: "forDistricts",
  isFilterable: true,
  isSortable: true,
  renderListValue: (item: RoleFull) => formatBoolean(item.forDistricts),
};
